/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

.ql-editor{
    min-height: 100px !important;
    max-height: 400px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
}

.ql-toolbar.ql-snow{
    border-radius: 10px 10px 0 0;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow{
    border-radius: 0 0 10px 10px;
}
.ql-editor.ql-blank::before {
    font-style: unset;
}

