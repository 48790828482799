/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

.container {
    z-index: 1;
    box-shadow: -7px -1px 8px #f0f1f2;
    padding: 20px;
    height: 100%;
}

.operationsContainer {
    text-align: center;
}

.title{
    margin-left: 18px;
    color: rgba(0, 0, 0, 0.70) !important;
}

.operation {
    padding: 8px 4px;
}

.operationDescription {
    font-size: 12px;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 32px
}

.spinner {
    margin-top: 100px;
    text-align: center;
    margin-left: 45%;
}
