/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

.content {
    padding: 30px 70px 0 70px;
}

.pageHeader {
    border-radius: 10px;
    padding-top: 16px;
    box-shadow: 0 5px 8px #f0f1f2;
    z-index: 2;
}

.icon{
    color: rgba(0, 0, 0, 0.65);
    margin-right: 6px;
    padding-left: 46px;
}

.col {
    margin-right: 20px;
}

.billPeriod {
    margin-right: 140px;
}

.generateBillCheckBox {
    margin-top: 6px;
    margin-right: 20px;
}

.tableContainer {
    border: 1px solid rgba(187, 187, 187, 0.2);
    border-radius: 7px;
    margin-top: -10px;
}

.container {
    z-index: 1;
    box-shadow: 0 14px 29px 0 rgba(171, 171, 171, 0.3);
    padding: 20px;
    height: 100%;
}

.title{
    text-align: center;
    margin-left: 18px;
    color: rgba(0, 0, 0, 0.70) !important;
    margin-bottom: 30px !important;
}