/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

.leaflet-container {
    align-content: center;
    padding-top: 80px;
    height: 550px;
    width: 100%;
}

.locationMap{
    align-content: center;
    padding-top: 80px;
    height: 750px;
}


.controllerDiv {
    padding-bottom: 30px;
}

.customTooltip {
    background: transparent;
    border:0;
    box-shadow: none;
}

.tooltip {
    background: transparent;
    border:0;
    box-shadow: none;
    font-size: 15px;
    font-weight: bold;
    margin-top: 45px;
}
