/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

.profile {
    float: right;
    margin-right: 2%;
}

.logoImage {
    height: 45px;
    margin: 0 5px 16px 24px;
    float: left;
}

.logoImage img {
    height: 35px;
}

.header {
    background: #ffffff;
    margin: 10px 20px 0 20px;
    border-radius: 10px;
    padding: 0 20px 0 20px !important;
    z-index: 10;
}

.rightAlignedMenu {
    float: right;
    width: 300px;
}

.avatar {
    background-color: #4288BA;
}

.logo {
    margin: 16px;
    border-radius: 10px;
}

.logo img {
    width: 90%;
}

.txtWhite {
    color: white;
}

.main-footer {
    height: 80px;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
}

/*.react-joyride__tooltip button {*/
/*    background-color: red;*/
/*}*/

/*.react-joyride__tooltip button:focus {*/
/*    outline: none !important;*/
/*    background-color: red !important;*/
/*    border-radius: 0px !important;*/
/*    border-color: red !important;*/
/*}*/

/* css handling for device type menu*/
/*TODO: change menufont color*/
.ant-menu.ant-menu-dark .ant-menu-item-selected.custom-menu-item {
    background-color: transparent;
    border-bottom: 3px solid #942929;
    color: black;
}
.ant-menu.ant-menu-dark .ant-menu-item-active.custom-menu-item:hover {
    background-color: transparent;
    border-bottom: 3px solid #6c0202;
}
.ant-menu.ant-menu-dark .ant-menu-item.custom-menu {
    color: green;
}
.ant-menu-inline .ant-menu-item.custom-side-menu-item::after{
    border-right: 0 none;
}
.ant-menu-inline .ant-menu-item.custom-side-menu-item{
    width: calc(94% + 1px);
    margin-left: 3%;
}
.ant-menu-item-selected.custom-side-menu-item a{
    color: #ffffff;
}
.ant-menu-item.custom-side-menu-item a{
    font-size: 15px;
}
.ant-menu-item.custom-side-menu-item a:hover{
    color: #ffffff;
}

.customItem {
    width: calc(94% + 1px);
    margin-left: 3%;
}

.custom-sider {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 992px) {
    .header {
        background-color: white;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1222px){
    .searchBar {
        width: 200px
    }
    .rightAlignedMenu {
        float: right;
        width: 400px;
    }
}

@media only screen and (max-width: 1024px) {
    .rightAlignedMenu {
        float: right;
        width:100px;
    }
}


.searchIcon {
    margin-top: 20px;
}

.searchBar {
    margin-top: 16px;
    width: 200px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #d9d9d9;
    box-shadow: none;
}

.breadCrumbList {
    margin-bottom: 2px;
    font-size: 12px;
}

@media only screen and (min-width: 1022px) {
    .searchBar {
        width: 400px
    }
}

.notificationButton {
    font-size: 20px !important;
    margin-right: 0 !important;
    vertical-align: middle;
}
