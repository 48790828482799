/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

.pageHeader {
    padding-top: 16px;
    border-radius: 10px;
    box-shadow: 0 5px 8px #f0f1f2;
    z-index: 2;
}

.table {
    padding: 15px 70px;
}

.tooltip {
    position: absolute;
    background-color: #FAF9F6;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
    left: calc(50% + 300px);
    top: 5%;
    transform: translateX(-100%);
    font-size: 11px;
    padding: 5px;
}

.summary {
    position: absolute;
    background-color: #c8c8c8;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
    transform: translateX(-100%);
    font-size: 11px;
    padding: 5px;
}

.hoverDiv {
    background-color: orange;
}  

.hoverDiv:hover {
    background-color: #e8e8e8;
}

.customTable .ant-table  .table{
    font-size: 5px;
}